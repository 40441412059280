import { Octokit } from "@octokit/rest";
import {
  SearchPullRequestsResponseData,
  GithubRepositoryData,
  GithubFirstCommitDate,
} from "../../types";
import { GithubPullRequestsApi } from "../types";

export class GithubPullRequestsClient implements GithubPullRequestsApi {
  async listPullRequests({
    search = "",
    token,
    owner,
    repo,
    pageSize = 5,
    page,
    baseUrl,
  }: {
    search: string;
    token: string;
    owner: string;
    repo: string;
    pageSize?: number;
    page?: number;
    baseUrl: string | undefined;
  }): Promise<{
    pullRequestsData: SearchPullRequestsResponseData;
  }> {
    const pullRequestResponse = await new Octokit({
      auth: token,
      ...(baseUrl && { baseUrl }),
    }).search.issuesAndPullRequests({
      q: `${search} in:title type:pr repo:${owner}/${repo}`,
      per_page: pageSize,
      page,
    });
    return {
      pullRequestsData:
        pullRequestResponse.data as SearchPullRequestsResponseData,
    };
  }
  async getRepositoryData({
    baseUrl,
    token,
    url,
  }: {
    baseUrl: string | undefined;
    token: string;
    url: string;
  }): Promise<GithubRepositoryData> {
    const response = await new Octokit({
      auth: token,
      ...(baseUrl && { baseUrl }),
    }).request({ url: url });

    return {
      htmlUrl: response.data.html_url,
      fullName: response.data.full_name,
      additions: response.data.additions,
      deletions: response.data.deletions,
      changedFiles: response.data.changed_files,
    };
  }

  async getCommitDetailsData({
    baseUrl,
    token,
    owner,
    repo,
    number,
  }: {
    baseUrl: string | undefined;
    token: string;
    owner: string;
    repo: string;
    number: number;
  }): Promise<GithubFirstCommitDate> {
    const { data: commits } = await new Octokit({
      auth: token,
      ...(baseUrl && { baseUrl }),
    }).pulls.listCommits({ owner: owner, repo: repo, pull_number: number });
    const firstCommit = commits[0];
    return {
      firstCommitDate: new Date(firstCommit.commit.author!.date!),
    };
  }
}
