import { useEffect, useState } from "react";
import { useAsyncRetry } from "react-use";
import { githubPullRequestsApiRef } from "../api";
import { useApi, githubAuthApiRef } from "@backstage/core-plugin-api";
import { SearchPullRequestsResponseData } from "../types";
import { useBaseUrl } from "./useBaseUrl";
import { DateTime } from "luxon";

export type PullRequest = {
  id: number;
  number: number;
  url: string;
  title: string;
  updatedTime: string | null;
  createdTime: string | null;
  state: string;
  draft: boolean;
  merged: string | null;
  creatorNickname?: string | null;
  creatorProfileLink?: string | null;
  body: string;
};

export function usePullRequests({
  search,
  owner,
  repo,
  branch,
}: {
  search: string;
  owner: string;
  repo: string;
  branch?: string;
}) {
  const api = useApi(githubPullRequestsApiRef);
  const auth = useApi(githubAuthApiRef);
  const baseUrl = useBaseUrl();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const getElapsedTime = (start: string): string | null => {
    return DateTime.fromISO(start).toRelative();
  };

  const {
    loading,
    value: prData,
    retry,
    error,
  } = useAsyncRetry<PullRequest[]>(async () => {
    const token = await auth.getAccessToken(["repo"]);
    if (!repo) {
      return [];
    }
    return (
      api
        // GitHub API pagination count starts from 1
        .listPullRequests({
          token,
          search,
          owner,
          repo,
          pageSize,
          page: page + 1,
          branch,
          baseUrl,
        })
        .then(
          ({
            pullRequestsData: { total_count, items },
          }: {
            pullRequestsData: SearchPullRequestsResponseData;
          }) => {
            if (total_count >= 0) {
              setTotal(total_count);
            }
            return items.map(
              ({
                id,
                html_url,
                title,
                number,
                created_at,
                updated_at,
                user,
                state: pr_state,
                draft,
                pull_request: { merged_at },
                body,
              }) => ({
                url: html_url,
                id,
                number,
                title,
                body: body!,
                state: pr_state,
                draft,
                merged: merged_at,
                creatorNickname: user?.login,
                creatorProfileLink: user?.html_url,
                createdTime: getElapsedTime(created_at),
                updatedTime: getElapsedTime(updated_at),
              }),
            );
          },
        )
    );
  }, [page, pageSize, repo, owner]);
  useEffect(() => {
    setPage(0);
    retry();
  }, [search]);
  return [
    {
      page,
      pageSize,
      loading,
      prData,
      projectName: `${owner}/${repo}`,
      total,
      error,
    },
    {
      prData,
      setPage,
      setPageSize,
      retry,
    },
  ] as const;
}
