import { Grid } from "@material-ui/core";
import { EntitySwitch } from "../../components/catalog";
import { ReadmeCard } from "../../components/github";
import {
  EntityGitlabReadmeCard,
  isGitlabAvailable,
} from "../../components/gitlab";
import { isGithubAvailable } from "../../components/github";

export const readmeContent = (
  <Grid item md={12}>
    <EntitySwitch>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <EntityGitlabReadmeCard />
      </EntitySwitch.Case>
      <EntitySwitch.Case if={isGithubAvailable}>
        <ReadmeCard />
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
