import { useProjectEntity } from "../../../hooks/useProjectEntity";
import { useEntity } from "@backstage/plugin-catalog-react";
import { useEntityGithubScmIntegration } from "../../../hooks/useEntityGithubScmIntegration";
import { Card } from "@material-ui/core";
import { MarkDownContent } from "../MarkDownContent";

export const ReadmeCard = () => {
  const { entity } = useEntity();
  const { owner, repo, readmePath } = useProjectEntity(entity);
  const { baseUrl } = useEntityGithubScmIntegration(entity);

  return (
    <Card>
      <MarkDownContent
        baseUrl={baseUrl}
        owner={owner}
        repo={repo}
        path={readmePath}
      />
    </Card>
  );
};
