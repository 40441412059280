import {
  EntityGithubPullRequestsContent,
  isGithubAvailable,
} from "../../components/github";
import { Button, EmptyState } from "@backstage/core-components";
import { EntitySwitch } from "../../components/catalog";
import {
  EntityGitlabMergeRequestsTable,
  isGitlabAvailable,
} from "../../components/gitlab";

export const PullRequestContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubAvailable}>
      <EntityGithubPullRequestsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabMergeRequestsTable />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No Pull Request available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable Pull Request for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
            to={""}
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);
