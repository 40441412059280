import { Entity } from "@backstage/catalog-model";

export const GITHUB_INSTANCE = "github.com/instance";
export const WORKFLOW_ANNOTATION = "github.com/workflows";
export const GITHUB_ANNOTATION = "github.com/project-slug";
export const GITHUB_PULL_REQUESTS_TEAM_ANNOTATION = "github.com/team-slug";

export const isGithubSlugSet = (entity: Entity) => {
  return entity?.metadata.annotations?.[GITHUB_ANNOTATION] ?? "";
};

export const isGithubTeamSlugSet = (entity: Entity) => {
  return (
    entity?.metadata.annotations?.[GITHUB_PULL_REQUESTS_TEAM_ANNOTATION] ?? ""
  );
};
