import { useApi, configApiRef } from "@backstage/core-plugin-api";

export const useBaseUrl = () => {
  const config = useApi(configApiRef);
  const providerConfigs =
    config.getOptionalConfigArray("integrations.github") ?? [];
  const targetProviderConfig = providerConfigs[0];
  const baseUrl = targetProviderConfig?.getOptionalString("apiBaseUrl");
  return baseUrl;
};
