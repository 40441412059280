import { Entity } from "@backstage/catalog-model";
import { Route, Routes } from "react-router";
import PullRequestsPage from "./pullRequest/components/PullRequestsPage";
import {
  GITHUB_ANNOTATION,
  GITHUB_PULL_REQUESTS_TEAM_ANNOTATION,
  WORKFLOW_ANNOTATION,
} from "./utils/isGithubSlugSet";
import { MissingAnnotationEmptyState } from "@backstage/core-components";
import { useEntity } from "@backstage/plugin-catalog-react";

export const isGithubPullRequestsAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[GITHUB_ANNOTATION]);

export const isGithubWorkflowsAvailable = (entity: Entity) =>
  !!entity?.metadata.annotations?.[WORKFLOW_ANNOTATION];

export const isGithubAvailable = (entity: Entity) =>
  !!entity?.metadata.annotations?.[GITHUB_ANNOTATION];

export const isGithubTeamPullRequestsAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[GITHUB_PULL_REQUESTS_TEAM_ANNOTATION]);

export const Router = () => {
  const { entity } = useEntity();
  return !isGithubAvailable(entity) ? (
    <MissingAnnotationEmptyState annotation={GITHUB_ANNOTATION} />
  ) : (
    <Routes>
      <Route path="/" element={<PullRequestsPage />} />
    </Routes>
  );
};
