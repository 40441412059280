import { GithubApi } from "../types";
import { OAuthApi } from "@backstage/core-plugin-api";
import { Octokit } from "@octokit/rest";
import parseGitUrl from "git-url-parse";
import { MarkdownContentProps } from "../../readme/types";

const mimeTypeMap: Record<string, string> = {
  svg: "image/svg+xml",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  webp: "image/webp",
};

const mimeTypeLookup = (href: string): string | undefined => {
  const match = href.match(/\.([a-zA-Z]*)$/);
  const extension = match ? match[1] : undefined;
  return extension ? mimeTypeMap[extension.toLowerCase()] : undefined;
};

const getRepositoryDefaultBranch = (url: string) => {
  return new URL(url).searchParams.get("ref");
};

const defaultBaseUrl = "https://api.github.com";

const combinePaths = (readmePath: string, relativePath: string): string => {
  const readmeUrl = new URL(`file://${readmePath}`);
  const dirUrl = new URL(".", readmeUrl);
  const combinedUrl = new URL(relativePath, dirUrl);

  return combinedUrl.pathname.startsWith("/")
    ? combinedUrl.pathname
    : `/${combinedUrl.pathname}`;
};

export class GithubReadmeClient implements GithubApi {
  private githubAuthApi: OAuthApi;

  constructor(deps: { githubAuthApi: OAuthApi }) {
    this.githubAuthApi = deps.githubAuthApi;
  }

  async getContent(props: MarkdownContentProps): Promise<{
    content: string;
    media: Record<string, string>;
    links: Record<string, string>;
  }> {
    const {
      path: customReadmePath,
      repo,
      owner,
      branch,
      baseUrl = defaultBaseUrl,
    } = props;
    const token = await this.githubAuthApi.getAccessToken();
    const octokit = new Octokit({ auth: token, baseUrl });

    let query = "readme";
    if (customReadmePath) {
      query = `contents/${customReadmePath}`;
    }

    const readmePath = query;

    const response = await octokit.request(
      `GET /repos/{owner}/{repo}/${query}`,
      {
        owner,
        repo,
        ...(branch && { ref: branch }),
      },
    );
    const content = Buffer.from(response.data.content, "base64").toString(
      "utf8",
    );

    const mediaLinks = [
      ...content.matchAll(
        /!\[([^\]]*)\]\(([^)]+\.(?:png|jpg|jpeg|gif|webp|svg))\)/gi,
      ),
    ].map((match) => [match[2], match[3]].join(""));

    const media: Record<string, string> = {};

    const { ref, resource: domain, protocol } = parseGitUrl(response.data.url);
    const domainLowerCased = domain.toLocaleLowerCase("en-US");
    for (const mediaLink of mediaLinks) {
      const mimeType = mimeTypeLookup(mediaLink);
      if (!mimeType) {
        continue;
      }

      const getUrl = () => {
        const linkLowerCased = mediaLink.toLocaleLowerCase("en-US");
        if (!linkLowerCased.startsWith("http")) {
          return new URL(mediaLink, response.data.url);
        }

        if (linkLowerCased.includes(domainLowerCased)) {
          const {
            owner: ownerLink,
            name: repoLink,
            ref: refLink,
            filepath,
          } = parseGitUrl(mediaLink);
          if (filepath) {
            return `/repos/${ownerLink}/${repoLink}/contents/${filepath}${
              refLink && `?ref=${refLink}`
            }`;
          }
        }
        return undefined;
      };

      const url = getUrl();
      if (url) {
        try {
          const contentResponse = await octokit.request(`GET ${url}`);
          media[mediaLink] =
            `data:${mimeType};base64,${contentResponse.data.content.replaceAll(
              "\n",
              "",
            )}`;
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.warn(
              `There was a problem loading the image content at ${url} via the GitHub API due to error: ${error.message}`,
            );
          }
        }
      }
    }

    const markdownLinks = [
      ...content.matchAll(/\[([^[\]]*)\]\((?!https?:\/\/)(.*?)(\.md)\)/gim),
    ].map((match) => [match[2], match[3]].join(""));

    const links: Record<string, string> = {};

    const loadFromBranch =
      branch || ref || getRepositoryDefaultBranch(response.data.url);

    for (const markdownLink of markdownLinks) {
      const basicLink = `${protocol}://${domain}/${owner}/${repo}/blob/${loadFromBranch}`;

      const combinedPath = combinePaths(readmePath, markdownLink);
      links[markdownLink] = `${basicLink}${combinedPath}`;
    }
    return { content, media, links };
  }
}
