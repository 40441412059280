import {
  createPlugin,
  createApiFactory,
  createRouteRef,
  createRoutableExtension,
  createComponentExtension,
  configApiRef,
} from "@backstage/core-plugin-api";
import { createCardExtension } from "@backstage/plugin-home-react";

import {
  githubPullRequestsApiRef,
  githubWorkflowsApiRef,
  GithubPullRequestsClient,
  GithubWorkflowsClient,
} from "./api";
import { scmAuthApiRef } from "@backstage/integration-react";
import { buildRouteRef, rootRouteRef } from "./workflow/routes";

export const entityContentRouteRef = createRouteRef({
  id: "github-pull-requests",
});

export const githubPullRequestsPlugin = createPlugin({
  id: "github-pull-requests",
  apis: [
    createApiFactory(githubPullRequestsApiRef, new GithubPullRequestsClient()),
  ],
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const githubWorkflowsPlugin = createPlugin({
  id: "githubWorkflows",
  apis: [
    createApiFactory({
      api: githubWorkflowsApiRef,
      deps: { configApi: configApiRef, scmAuthApi: scmAuthApiRef },
      factory: ({ configApi, scmAuthApi }) => {
        return new GithubWorkflowsClient({ configApi, scmAuthApi });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
    buildRoute: buildRouteRef,
  },
});

export const GithubWorkflowsContent = githubWorkflowsPlugin.provide(
  createRoutableExtension({
    name: "GithubWorkflowsContent",
    component: () =>
      import(
        "./workflow/components/GithubWorkflowsContent/GithubWorkflowsContent"
      ).then((m) => m.GithubWorkflowsContent as any),
    mountPoint: rootRouteRef,
  }),
);

export const EntityGithubPullRequestsContent = githubPullRequestsPlugin.provide(
  createRoutableExtension({
    name: "EntityGithubPullRequestsContent",
    component: () => import("./Router").then((m) => m.Router),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityGithubPullRequestsOverviewCard =
  githubPullRequestsPlugin.provide(
    createComponentExtension({
      name: "EntityGithubPullRequestsOverviewCard",
      component: {
        lazy: () =>
          import("./pullRequest/components/PullRequestsStatsCard").then(
            (m) => m.PullRequestsStatsCard,
          ),
      },
    }),
  );

export const EntityGithubPullRequestsTable = githubPullRequestsPlugin.provide(
  createComponentExtension({
    name: "EntityGithubPullRequestsTable",
    component: {
      lazy: () =>
        import("./pullRequest/components/PullRequestsTable").then(
          (m) => m.PullRequestsTable,
        ),
    },
  }),
);

export const HomePageRequestedReviewsCard = githubPullRequestsPlugin.provide(
  createComponentExtension({
    name: "HomePageRequestedReviewsCard",
    component: {
      lazy: () =>
        import("./pullRequest/components/RequestedReviewsCard").then(
          (m) => m.Content,
        ),
    },
  }),
);

export const HomePageYourOpenPullRequestsCard =
  githubPullRequestsPlugin.provide(
    createComponentExtension({
      name: "YourOpenPullRequestsCard",
      component: {
        lazy: () =>
          import("./pullRequest/components/YourOpenPullResquestsCard").then(
            (m) => m.Content,
          ),
      },
    }),
  );

export const EntityGithubGroupPullRequestsCard =
  githubPullRequestsPlugin.provide(
    createCardExtension({
      name: "EntityGithubGroupPullRequestsCard",
      title: "Team Assigned Review requests",
      components: () =>
        import("./pullRequest/components/GroupPullRequestsCard"),
    }),
  );
