import { EmptyState } from "@backstage/core-components";
import { EntitySwitch } from "../../components/catalog";
import {
  EntityGitlabPipelinesTable,
  isGitlabAvailable,
} from "../../components/gitlab";
import {
  GithubWorkflowsContent,
  isGithubAvailable,
} from "../../components/github";
import { EntityGithubActionsContent } from "@backstage-community/plugin-github-actions";

export const cicdContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubAvailable}>
      <div style={{ marginBottom: "20px" }}>
        <GithubWorkflowsContent cards />
      </div>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabPipelinesTable />
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it."
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);
