import { createApiRef } from "@backstage/core-plugin-api";
import {
  GithubReadmeApi,
  GithubPullRequestsApi,
  GithubWorkflowsApi,
} from "./types";

export const githubReadmeApiRef = createApiRef<GithubReadmeApi>({
  id: "plugin.github.readme.service",
});

export const githubPullRequestsApiRef = createApiRef<GithubPullRequestsApi>({
  id: "plugin.github.pullrequests.service",
});

export const githubWorkflowsApiRef = createApiRef<GithubWorkflowsApi>({
  id: "plugin.github.workflows.service",
});
