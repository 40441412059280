import { Entity } from "@backstage/catalog-model";

export const ROADIE_BACKSTAGE_PULL_REQUESTS_DEFAULT_FILTER_ANNOTATION =
  "roadie-backstage-pull-requests/default-filter";

export const isRoadieBackstageDefaultFilterSet = (entity: Entity) => {
  return (
    entity?.metadata.annotations?.[
      ROADIE_BACKSTAGE_PULL_REQUESTS_DEFAULT_FILTER_ANNOTATION
    ] ?? ""
  );
};
